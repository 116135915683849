import { makeDecimal } from "@frec-js/common";
import Decimal from "decimal.js";

// Based on James' Direct Indexing simulations Oct 2023
export const alpha = new Decimal(0.0211);
export const alphaPercentStr = alpha.times(100).toFixed(2);

type Data = {
  name: string;
  sp500: number;
  sp500DI: number;
  sp500DIDiff: number;
};

export enum TimeHorizon {
  OneYear = "1",
  // ThreeYears = "3",
  FiveYears = "5",
  // SevenYears = "7",
  TenYears = "10",
}

export const useDirectIndexCalculatorData = (
  principal: Decimal,
  timeHorizon: TimeHorizon,
): Data[] | undefined => {
  const years = parseInt(timeHorizon);

  const sp500return = makeDecimal(10.5).div(100).plus(1);

  const data: Data[] = [];
  let sp500 = principal;
  let sp500DI = principal;
  for (let i = 0; i <= years; i++) {
    data.push({
      name: `${i}`,
      sp500: sp500.toNumber(),
      sp500DI: sp500DI.toNumber(),
      sp500DIDiff: sp500DI.minus(sp500).toNumber(),
    });
    sp500 = sp500.times(sp500return);
    sp500DI = sp500DI.times(sp500return.plus(alpha));
  }
  return data;
};
