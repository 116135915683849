import { colors } from "@frec-js/common";
import NextLink, { LinkProps } from "next/link";
import {
  FC,
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useCallback,
} from "react";

import { classNames } from "../utils/classNames";

export type FullLinkProps = LinkProps & {
  className?: string;
  children?: ReactNode;
  href: string;
  target?: string;
};

export const TextLink = forwardRef<HTMLAnchorElement, FullLinkProps>(
  function LinkButton({ className, ...props }, ref) {
    return (
      <NextLink
        {...props}
        className={classNames(
          "hover:bg-frecNeon dark:hover:text-frecBlack underline",
          className,
        )}
        ref={ref}
      />
    );
  },
);

export const Footnote: FC<
  PropsWithChildren<{
    anchor: string;
    className?: string;
  }>
> = ({ anchor, children, className }) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      const element = document.getElementById(anchor);
      if (!element) return;
      e.preventDefault();
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      element.style.transitionDuration = "0s";
      element.style.backgroundColor = colors.frecNeon;
      element.style.transitionDuration = "1s";
      setTimeout(() => {
        if (element) {
          element.style.backgroundColor = "transparent";
        }
      }, 1000);
    },
    [anchor],
  );
  return (
    <TextLink
      className={classNames(className, "!no-underline")}
      onClick={handleClick}
      href={`#${anchor}`}
    >
      {children}
    </TextLink>
  );
};

export const SmoothScrollLink: FC<
  PropsWithChildren<{
    anchor: string;
    className?: string;
  }>
> = ({ anchor, children, className }) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      const element = document.getElementById(anchor);
      if (!element) return;
      e.preventDefault();
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    },
    [anchor],
  );
  return (
    <TextLink
      className={classNames(
        className,
        "!border-inherit !no-underline hover:bg-transparent",
      )}
      onClick={handleClick}
      href={`#${anchor}`}
    >
      {children}
    </TextLink>
  );
};
